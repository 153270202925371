import React, {
    useEffect,
    useState
} from 'react';
import styled from 'styled-components';
import IMAGES from 'constants/Images';
import {
   downloadESignForm
} from "api";
import {
    triggerToast,
    handleApiError
} from "helpers";

import {
    ActionButton
} from "screens/commons";
import {
 ProgressBar
} from 'components/ProgressBar';


const convertBase64ToBlobUrl = (base64, type ="application/pdf") => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length).fill()
        .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type});
    return URL.createObjectURL(blob);
};

function OfflineSignComponent({kycData}) {

    const [isLoading, setIsLoading] = useState(false);
    const [pdfSrc, setPdfSrc] = useState(null); 

    useEffect(()=>{
        fetchForm();
    },[]);

    const downloadForm = () => {
        if(!pdfSrc){    
            fetchForm();
            return; 
        }
        const pdf = pdfSrc?.form;
        const fileName = pdfSrc?.fileName ?? "Account_Opening_From_Wealthy.pdf";


        if (!pdf) {
            triggerToast("Failed to download form. Something went wrong");
            return;
        }

        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const fetchForm = () =>  {
        setIsLoading(true);
        downloadESignForm().then(res=> {
            const pdf = res?.data?.form;
            if(!pdf) {
                triggerToast("Failed to download form. Something went wrong");
                return;
            }
            setPdfSrc(res?.data);
        }).catch(e => handleApiError(e)).finally(()=>{
            setIsLoading(false);
        });
    };


    return <Wrapper>
        <div className='info-container'>
            <InfoIcon src={IMAGES.infoIconImage} />
            <p>Attention</p>
        </div>
        <p> You have uploaded the non Aadhaar Documents. Please download the form, sign and post it to Wealthy </p>
        {isLoading || !pdfSrc ? <ProgressBar waitTimeInSec={180} /> :  
        <object
             className='pdf-viewer'
            data={convertBase64ToBlobUrl(pdfSrc.form, "application/pdf")}
            height="400px"
            type="application/pdf"
            width="100%"
        /> 
       }
        <ActionButton
            buttonText={'Download Form'}
            callback={downloadForm}
            isLoading={isLoading}
            isDisabled={isLoading}
        />
    </Wrapper>;
}

export default OfflineSignComponent;


const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    height:100%;
    width:100%;
    font-family: MavenPro;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.8px;
    text-align: left;

    @media screen and (max-width:1023px) {
      padding:16px;
    }

    .pdf-viewer{
        flex-grow:1;
    }

    .info-container{
        display: flex;
        padding:8px;
        border-radius:8px;
    }

    a {
        font-weight:bold;
        width: 100%;
        text-decoration:none;
        margin-top: auto;
    }

`;

const InfoIcon = styled.img`
    height: 18px;
    width: 18px;
    margin-left: 4px;
    color: #666;
    margin-right: 4px;
`;